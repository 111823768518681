<template>
  <div class="preferential-policy">
    <Header></Header>
    <div class="main">
      <div class="flex flex-row align-center price-title">
        <img src="../assets/servePrice/card.png" alt="" />
        <span>优惠政策</span>
      </div>
      <div class="chart-wrap">
        <img src="../assets/home/loginBg.png" alt="" />
        <div class="project-wrap">
          <div class="project-title">
            <span>内江汽车服务有限公司</span>
            <span>公务用车优惠方案</span>
          </div>
          <div class="project-details">
            <div class="p1">
              <h2>一、短时短途用车优惠内容(此优惠不含自带驾驶员)</h2>
              <div class="p2">
                <h3>（一）用车时长不足2小时，里程不超过30公里。</h3>
                <p>
                  1.轿车：城区用车时长2小时内里程不超过30公里，按120包干收费；新车按200元收取包干使用费。
                </p>
                <p>
                  2.商务车、越野车：城区用车时长2小时内里程不超过30公里，按260元收取包干使用费。
                </p>
                <p>
                  3.中巴车（17座、18座、22座）：城区用车时长2小时内里程不超过30公里，按400元包干收费。
                </p>
              </div>
              <div class="p2">
                <h3>（二）用车时长不足4小时，里程不超过100公里。</h3>
                <p>
                  1.用车时长在4小时内，里程不超过100公里，租车费按《服务报价表》的5折优惠折算，驾驶员劳务费按《服务报价表》的7折优惠核算。
                </p>
                <p>
                  2.用车时长在4小时内，里程在100--200公里之间，租车费按《服务报价表》的5折优惠折算，驾驶员劳务费不打折。
                </p>
              </div>
            </div>
            <div class="p1">
              <h2>
                二、连续多日租车优惠内容<span class="sign">（此优惠不包括专项活动保障的租用车辆、1.6升及以下排量车辆）</span
                >
              </h2>
              <p>
                1.连续租车2天，租车费按照《服务报价表》9折优惠核算，驾驶员劳务费不打折。
              </p>
              <p>
                2.连续租车3-6日，租车费按照《服务报价表》8.5折优惠核算，驾驶员劳务费不打折。
              </p>
              <p>
                3.连续租车7-15日，租车费按照《服务报价表》8折优惠核算，驾驶员劳务费不打折。
              </p>
              <p>
                4.连续租车16日及以上，租车费按照《服务报价表》7.5折优惠核算，驾驶员劳务费不打折。
              </p>
              <p>
                5.下午、周末、法定节假日租车，租车费按照《服务报价表》9折优惠核算，驾驶员劳务费不打折，不得与其他优惠复重计算。此方案自2020年7月21日起执行，原公务车优惠方案终止。
              </p>
            </div>
            <span class="time">2020年7月21日</span>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "PreferentialPolicy",
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  methods: {
    loadData() {},
  },
  mounted() {
    this.loadData();
  },
};
</script>
<style scoped>
/* 当下 */
.preferential-policy {
  background-color: #f5f7fa;
}
.main {
  width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
}
.price-title {
  padding: 18px 0;
  position: relative;
  background-color: #f5f7fa;
}
.price-title::after {
  content: "";
  width: 1072px;
  height: 1px;
  background-color: #dadada;
  position: absolute;
  left: 135px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.price-title img {
  margin-right: 2px;
}
.price-title span {
  font-size: 18px;
  font-weight: 400;
  color: #141414;
}
.chart-wrap {
  padding-top: 13px;
  box-shadow: 1px 5px 9px 1px rgba(0, 0, 0, 0.18);
  margin-bottom: 20px;
}
.chart-wrap > img {
  width: 1174px;
  height: 175px;
}
/* 文章 */
.project-title{
    padding: 54px 0 45px 0;
}
.project-title span {
  display: block;
  text-align: center;
  font-size: 26px;
  font-weight: 500;
  color: #333333;
  line-height: 40px;
}
.project-details {
  width: 997px;
  padding-left: 40px;
  padding-bottom: 55px;
}
h2,
h3,
p {
  text-align: left;
  color: #5f5f5f;
}
.p1 > h2 {
  font-size: 18px;
}
.p2 > h3 {
  font-size: 16px;
}
.p2 p {
  font-size: 16px;
}
.p1,
.p2,
h2,
h3,
p {
  margin-bottom: 20px;
}
.sign{
  color: #F0A14C;
  font-weight: normal;
  font-size: 16px;
}
.time{
    display: block;
    text-align: right;
    margin-top: 100px;
}
</style>
